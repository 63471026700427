<template>
  <div class="wrapper">
    <div class="wrapper__container">
      <jublia-header />
      <div class="container">
        <jublia-nav-step :trad="'treatment.reminder.title'" />
      
        <ul class="list-disc ml-4">
          <li class="li"><jublia-p  v-html="$t('treatment.reminder.text1')" /><br /></li>
          <li class="li"><jublia-p  v-html="$t('treatment.reminder.text2')" /><br /></li>
        </ul>
        <!--  <jublia-h3 class="mb-4 title ">{{ $t('treatment.reminder.text1') }}</jublia-h3>
                <jublia-p class="text">{{ $t('treatment.reminder.text2') }}</jublia-p>
        <jublia-p class="text">{{ $t('treatment.reminder.text2') }}</jublia-p>-->
        <form @submit.prevent="goToNextStep(true)" class="mb-6">
          <input
            v-mask="['###-###-####']"
            v-model.trim="$v.phone.$model"
            type="tel"
            name="phone"
            class="w-full text-center  border border-jublia-blue  rounded-lg p-2 mt-6 text-base w-64"
            placeholder="000-000-0000"
          />
          <jublia-p tiny class="text mt-2">{{
            $t('treatment.reminder.fees')
          }}</jublia-p>
          <div @click="calanderfn()" class="flex mt-4">
            <span v-if="calander" class="puce2 selected  border border-jublia-blue border-4" style=" border:3px solde #006699 !important;" >✓</span>
            <span v-else class="puce2  border border-jublia-blue border-4"  style=" border:3px solde #006699 !important;"/>
            <jublia-p class="cursor-pointer  text-jublia-blue textline ml-2 pt-1" style="font-size: 1rem;">
              {{ $t('treatment.reminder.not') }}
            </jublia-p>
          </div>
        <!-- <pre>{{$v}}</pre>-->
          <div
            v-if="
              submited && (!$v.phone.minLength || !$v.phone.maxLength || error)
            "
            class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base"
          >
            {{ $t('treatment.reminder.errorPhone') }}
          </div>
        </form>
       
      
        <div class="w-full flex justify-between mb-4 text  text-black">
          <jublia-p class="cursor-pointer flex  textline " style="font-size: .9rem;">
             <!-- {{ $t('common.call') }}-->
            </jublia-p>
          <div @click="goToNextStep(false,false)" class="flex ">
            <span v-if="noSelected" class="puce selected  border border-black border-4" style=" border:3px solde #006699 !important;" >✓</span>
            <span v-else class="puce  border border-black border-4"  style=" border:3px solde #006699 !important;"/>
            <jublia-p class="cursor-pointer DINBold text-black textline" >
              {{ $t('common.noThanks') }}
            </jublia-p>
          </div>
        </div>
        <pop v-if="popup"  v-model:is-open="popup"  @close="close()"  :state="step" >
         <template  v-slot:header>
        <jublia-h1  style="margin-bottom: auto" class=" text-center DINBold text-jublia-blue">{{$t('treatment.reminder.popuptitle')}}</jublia-h1>
        </template>
        <template  v-slot:body>
           <jublia-p class="text" style="text-align: center !important;">{{$t('treatment.reminder.popuptext')}}</jublia-p>
           <jublia-p class="text" style="text-align: center !important; margin-bottom: 2rem;">{{$t('treatment.reminder.popuptext2')}}</jublia-p>
          <div
          v-if="(submited1)"
          class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
         <p class="text">  {{$t('reset.error') }}</p>
        </div>
          </template>
          <template  v-slot:footer>
             <div class="mt-14 w-full flex  justify-around items-center mb-4">
             <jublia-button
              
            :bdelete="true"
           @click="close();"
            class="relative mt-2 border-jublia-blue   mr-8"
           style="text-transform: uppercase;"
          >
          <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('user-settings.back') }}
          </div>
          </jublia-button>
           <jublia-button
              
            :bdelete="true"
           @click="confirm(true)"
            class="relative mt-2 border-jublia-blue"
           style="text-transform: uppercase;"
          >
           <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('form-din.valider') }}
         </div>
          </jublia-button>
          </div>
             </template>
      </pop>
      <pop v-if="popup2"  v-model:is-open="popup2"  @close="close()"  :state="step" :close="'close'">
         <template  v-slot:header>
        <jublia-h1  style="margin-bottom: auto" class=" text-center DINBold text-jublia-blue">{{$t('treatment.reminder.popuptitle1')}}</jublia-h1>
        </template>
        <template  v-slot:body>
           <jublia-p class="text" style="text-align: center !important; margin-bottom: 2rem;">{{$t('treatment.reminder.popuptext1')}}</jublia-p>
           <jublia-p class="text" style="text-align: center !important; margin-bottom: 2rem;">{{$t('treatment.reminder.popuptext21')}}</jublia-p>
          <div
          v-if="(submited1)"
          class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
         <p class="text">  {{$t('reset.error') }}</p>
        </div>
          </template>
          <template  v-slot:footer>
             <div class="mt-14 w-full flex  justify-around items-center mb-4">
            
           <jublia-button
              
            :bdelete="true"
           @click="gospam()"
            class="relative mt-2 border-jublia-blue"
           style="text-transform: uppercase;"
          >
           <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('common.next') }}
         </div>
          </jublia-button>
          </div>
             </template>
      </pop>
      <!-- @click.native="goToNextStep(true)"-->
        <div class="w-full md:mx-auto md:w-4/5 lg:w-2/3">
          <jublia-button
            @click.native="goToNextStep(calander,true)"
            bgpurple
            class="w-full flex justify-between items-center"
          >
            <jublia-loader v-if="isLoading" />
            <div v-else class="text-xl font-bold mr-2  uppercase">
              {{ $t('common.next') }}
            </div>
            <img
              v-if="!isLoading"
              src="@/assets/icons/next.png"
              alt="next button"
              width="10"
              height="10"
            />
          </jublia-button>
        </div>
        <jublia-breadcrumb :currentStep="3" :totalStep="4" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations,mapState } from 'vuex'
import useVuelidate from '@vuelidate/core'
import {maxLength, minLength} from '@vuelidate/validators'
import axios from 'axios';

//import { required, maxLength, minLength, email, sameAs } from "@vuelidate/lib/validators";
import { useI18n } from "vue3-i18n";
import { mask } from 'vue-the-mask'
import JubliaHeader from '@/components/header/jublia-header'
import JubliaNavStep from '@/components/nav-step/jublia-nav-step'
import JubliaButton from '@/components/button/jublia-button'
import JubliaP from '@/components/text/jublia-p'
import JubliaH3 from '@/components/text/jublia-h3'
import JubliaBreadcrumb from '@/components/breadcrumb/jublia-breadcrumb'
import JubliaLoader from '@/components/loader/jublia-loader'
import pop from '@/components/back-pop/back-pop'
export default {
  components: {
    'jublia-loader':JubliaLoader,
      pop,
     'jublia-header':JubliaHeader,
    'jublia-nav-step':JubliaNavStep,
    'jublia-button':JubliaButton,
 //JubliaH1,
    'jublia-p':JubliaP,
    'jublia-h3':JubliaH3,
   'jublia-breadcrumb': JubliaBreadcrumb,
   
  },
  directives: {
    mask,
  },
   setup () {
     
    return { $v: useVuelidate() }
  },
  computed: {
    ...mapState({
      schemaSelection: (state) => state.treatment.schemaSelection,
     url: (state) => state.login.uri,
     urlT: (state) => state.treatment.nbDays,
      nbMonthsPrescripted: (state) => state.treatment.nbMonthsPrescripted,
      nbDays: (state) => state.treatment.nbDays,
      phone: (state) => state.reminder.phone,
      nbBottles: (state) => state.treatment.nbBottles,
      reminder: (state) => state.reminder.subscribed,
       refill: (state) => state.reminder.refill,
       refillEmail:(state) => state.reminder.refillEmail
    }),
  }, 
  
  data: () => ({
   // $v1:null,
    isLoading: false,
    error: false,
    step:'op',
    popup:false,
    popup2:false,
    phone: null,
    submited: false,
    submited1: false,
    noSelected: false,
     noSelected1: false,
     calander:false
  }),
  
    mounted() {
   
    if(!!sessionStorage.getItem('user')){
    
      this.user= JSON.parse(sessionStorage.getItem('user'))||{}
      
   }
       const prev= sessionStorage.getItem('log')

     if(prev=='first'){
            sessionStorage.setItem('log','none')
     window.location.reload(false);
    
     } 
  },
 methods: {
  async fnn(){
    
        const datas = {
          reminder: this.reminder,
          refill:this.refill,
          refillEmail:this.refillEmail,
          
          nextReminder: this.nbDays,
          nbBottles: this.nbBottles,
          email:this.user.email||'',
          schemaSelection:this.schemaSelection,
          setNbMonthsPrescripted:this.nbMonthsPrescripted,
          phone: this.phone.toString(),
          lang: this.$i18n.getLocale()||'fr',
          uri:sessionStorage.getItem('uri')||null
         }
      //  }
        try {
          const { data } = await axios.post('/step/reffil', datas)
          this.isLoading = true
            if(data.user!=null)
             this.$store.dispatch('login/USER_DETAILS',data.user);
        } catch (e) {
          this.isLoading = true
          this.error = true
          this.displayForm = true
        }
   },
  async confirm(a){
    if(this.phone!=null) this.phone=this.phone.toString();
     const datas = {
          reminder: this.reminder,
          refill:this.refill,
          refillEmail:this.calander,
          nextReminder: this.nbDays,
          nbBottles: this.nbBottles,
          email:this.user.email||'',
          schemaSelection:sessionStorage.getItem('schemaSelection'),
          setNbMonthsPrescripted:this.nbMonthsPrescripted,
          phone: this.phone||'',
          lang: this.$i18n.getLocale()||'fr',
          uri:sessionStorage.getItem('uri')||null
         }
       const { data } = await axios.post('/step/reffil', datas)
        if(data.user!=null &&this.phone !== null) {
          this.$store.dispatch('login/USER_DETAILS',data.user);
                     this.popup=false
                     this.popup2=true
                     
                      if(a) {this.goToNextStep(true);}
        }else{
           this.popup=false
        }
        

   },
close(){
this.popup=false
this.popup2=false
},
gospam(){
this.popup=false
this.popup2=true
this.$router.push(`/step-treatment-progress`)
//console.log('vb')
},
   async goToNextStep(reminder,c) {
      this.submited = true
      this.error = false
      //this.popup=true
      this.noSelected = !reminder
       //console.log(this.calander,this.popup)
       
      if (reminder) {
       // if (this.$v.phone.$error) return null
        if(this.calander){
          this.popup=true
         // console.log(this.popup)
        // const { data } = await axios.post('/step/reffil', datas)

        }else{
          this.confirm(false)
          //console.log("fdfdf")
        }
        if (this.phone === null) {
          this.error = true
          return null
        }
       
       // this.phone.replace(/-/g, '')
        this.setPhone(this.phone)
         
      } else {
        if(c) {this.setPhone(this.phone)
         //console.log("fdfdf",c)
        }else{
        this.setPhone(null)
        }
      }
       
      //console.log(reminder)
        this.setRefill(reminder)
        this.setRefillEmail(this.calander)
        this.setSubscribed(true)
         if(!reminder&&this.phone !== null) {
          this.$router.push(`/step-treatment-progress`)
                    
        }
        if(!reminder&&!c) {
          this.$router.push(`/step-treatment-progress`)
                    
        }
     // this.$router.push(`/step-treatment-progress`)
    },
    goTo(reminder1) {
      this.submited1 = true
      this.error = false
      this.noSelected1 = !this.noSelected1;
       this.setSubscribed(reminder)
      //this.$router.push(`/form-treatment-follow/step-treatment-progress`)
    },
    calanderfn() {
      
      this.calander = !this.calander;
      this.setRefillEmail(this.calander)
      //this.$router.push(`/form-treatment-follow/step-treatment-progress`)
    },
    ...mapMutations({
      setPhone: 'reminder/setPhone',
      setSubscribed: 'reminder/setSubscribed',
       setRefill: 'reminder/setRefill',
       setRefillEmail:'reminder/setRefillEmail'
    }),
  },
  validations () {
  return { phone: {
      minLength: minLength(12),
      maxLength: maxLength(12),
  
    }
  }
  }
}
</script>
<style scoped>
.button {
  @apply w-10 h-10  border border-jublia-emperor text-base text-jublia-emperor;
}

.active {
  @apply shadow-md border-2 border-jublia-purple text-jublia-purple;
  font-family: DINNextLTPro-Bold;
}

.puce {
  @apply block  text-lg  flex justify-center items-center cursor-pointer rounded-sm;
  width: 20px;
border: 2px solid black;
  height: 20px;
  margin-right: 5px;
  padding-top: 4px;
}
.puce2 {
  @apply rounded;
 display: block;
font-size: 1.125rem;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
width: 25px;
height: 25px;
margin-right: 5px;
padding-top: 4px;
/*border: 2px solid aquamarine;*/
}
.textline {
  line-height:123% !important;
}
.textline2 {
  line-height:163% !important;
}
.selected {
  @apply bg-jublia-white;
}
</style>
